html, body {
    background: #EEE;
    margin: 0;
    padding: 0;
}

.app {
    margin: auto;
    margin-top: 50px;
    width: max-content;
    max-width: 100vw;
}

.bar {
    background: white;
    margin: auto;
    box-sizing: border-box;
    border: 10px solid #EEE;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.bar input {
    border: none;
    padding: 5px;
    min-width: 70%;
    flex-grow: 10;
}

.bar select {
    flex-grow: 1;
}

.preview {
    background: white;
    display: block;
    margin: auto;
    box-sizing: border-box;
    border: 10px solid #EEE;
    width: 256px;
    max-width: 100%;
    height: auto;
}

.link {
    background: white;
    margin: 10px;
    padding: 5px;
    max-width: 480px;
    min-height: 10px;
    display: block;
    text-align: center;
    word-break: break-word;
}
